import React, { useEffect, } from "react"
import { Link, graphql} from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Seo from "./seo"
import Layout from './layout' 
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Imageholder from "../components/image"


const MdxPage = ({data, pageContext, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  const toccy = () => {
    var tocElem = document.querySelector("#toc");
    var container = document.querySelector("#contentarticle");
    var matches = container.querySelectorAll("h1,h2,h3,h4,h5"), i;
    var toc = "<div class='overflow-container vh-100 pb3 pl2'>";
    toc += "<div class='f8 ttu fw5 gray-900 bb b-black-10 pt2 pb05 mb1 ls-sm' style='padding-left: 0px;'>Page Contents</div>";
    for (i = 0; i < matches.length; ++i) {
      matches[i].classList.add("mb0", "orange-900");
      matches[i].insertAdjacentHTML('afterend', '<section><hr/></section>');
      var titleText = matches[i].textContent;
      var link = i;
      var att = document.createAttribute("id");
      att.value =  link;
      matches[i].setAttributeNode(att);
      toc += "<div class='pb075 pr1 lh-075 ls-xsm'><a class='f7 gray-900 link underline-hover:hover pointer pl0' style='padding-left: 0px; ' href=\"#" + link + "\">" + titleText + "</a></div>";
    }
    toc += "</div>";
    tocElem.innerHTML += toc;
  };



useEffect(() => {
  console.log('mounted');
  var el = document.querySelector("#toc");
  if( el ){ toccy() } 
  return () => console.log('unmounting...');
}, []) 

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
    offset: 50
})
  
}


return (
<Layout location={location} title={siteTitle}>
    <Seo
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
    />
    
   <div id="contentarticle" className="bg-white cf animate-03 fadeIn"  >
        <MDXRenderer
          remoteImages={post.frontmatter.embeddedImagesRemote}
          localImages={post.frontmatter.embeddedImagesLocal}
        >
          {post.body}
        </MDXRenderer>
  </div>

  <div className="w-100 bg-black-700" >
   <div className="mw-1200 center flex flex-column flex-row-m ">
          <div className="w-100 ph3 pv4">
            {next && (
              <Link className="link flex-center flex flex-column flex-row-s items-center tc  mw-500" to={`/${next.slug}`} rel="next">
                 <Imageholder className="br-100 b-grey w7 h7 overflow-hidden bw3 obj-cover" name={next.frontmatter.imagename} />
                <div className="pt1 white-70">←  {next.frontmatter.title}</div>
              </Link>
            )}
          </div>
          <div className="w-100 ph3 pv4">
            {previous && (
              <Link className="link flex-center flex flex-column flex-row-s items-center tc mw-500" to={`/${previous.slug}`} rel="prev">
                <Imageholder className="br-100 b-grey w7 h7 ba b-black-900 overflow-hidden  bw3 obj-cover" name={previous.frontmatter.imagename} />
                <div className="pt1 white-70">{previous.frontmatter.title} →</div>
              </Link>
            )}
          </div>
       
    </div>
    </div>



</Layout>
  );
};

export const query = graphql`
  query($id: String) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      slug
      frontmatter {
        title
        description
        order
        imagename
        embeddedImagesRemote {
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
            }
        embeddedImagesLocal {
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
            }
      }
      excerpt(pruneLength: 160)
      tableOfContents(maxDepth: 10)
      body
    }
  }
`;

export default MdxPage;
